<template>
  <div class="change-password">
    <h2 class="subtitle">{{ $t('changePassword.title') }}</h2>

    <form @submit.prevent="changePassword">
      <div v-if="error" class="notification is-danger">
        {{ error }}
      </div>

      <input-group
              type="password"
              :label="$t('changePassword.form.oldPassword')"
              v-model="oldPassword"
              name="oldPassword"
              required
      />

      <input-group
              type="password"
              :label="$t('changePassword.form.newPassword')"
              v-model="newPassword"
              name="newPassword"
              required
      />

      <input-group
              type="password"
              :label="$t('changePassword.form.newPasswordRepeat')"
              v-model="newPasswordRepeat"
              name="newPasswordRepeat"
              required
      />

      <button type="submit" class="button is-primary">{{ $t('common.submit') }}</button>
    </form>
  </div>
</template>

<script>
import InputGroup from '../basic/InputGroup'
import { mapState } from 'vuex'

export default {
  name: 'change-password',
  components: { InputGroup },
  data () {
    return {
      oldPassword: null,
      newPassword: null,
      newPasswordRepeat: null,
      error: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    async changePassword () {
      if (this.newPassword !== this.newPasswordRepeat) {
        this.error = this.$t('changePassword.form.error.passwordMismatch')

        return
      }

      if (this.newPassword === this.oldPassword) {
        this.error = this.$t('changePassword.form.error.passwordsIdentical')

        return
      }

      try {
        await this.$store.dispatch('changePassword', {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        })

        this.$router.push('/')
      } catch (error) {
        this.error = error.response.data.message
      }
    }
  }
}
</script>

<style scoped>

</style>
